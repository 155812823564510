import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { Field, Input, Label } from "@headlessui/react";

import { Category } from "../../types/Category";
import Dialog from "../ui/Dialog";
import FileDropZone from "../ui/FileDropZone";
import Checkbox from "../ui/Checkbox";

interface Props {
  category?: Category;
  onSubmit: (category: Partial<Category>) => void;
  onCancel: () => void;
}

export default function CategoryEditDialog({
  category,
  onSubmit,
  onCancel,
}: Props) {
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({
      name: category?.name ?? "",
      image_url: category?.image_url ?? "",
      is_featured_competition: category?.is_featured_competition ?? "",
    });
  }, [category, reset]);

  return (
    <Dialog title="Edit Category" open={!!category} onClose={onCancel}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field className="mb-4">
          <Label className="font-medium text-sm">Category Name:</Label>
          <Input
            {...register("name", { required: true })}
            placeholder="Enter category name"
            className="w-full mt-2 p-2 border rounded"
          />
          {errors.name && (
            <div className="text-sm ml-2 text-red-500">
              The field is required.
            </div>
          )}
        </Field>

        {category?.is_competition && (
          <>
            <Controller
              control={control}
              name="is_featured_competition"
              render={({ field }) => <Checkbox {...field} label="Featured" />}
            />
            <Controller
              control={control}
              name="image_url"
              rules={{ required: true }}
              render={({ field }) => (
                <div>
                  <label className="font-medium">Image: </label>
                  <FileDropZone label="Upload Image" {...field} />
                </div>
              )}
            />
          </>
        )}

        <div className="flex justify-end gap-2 mt-6">
          <button
            type="submit"
            className="bg-blue-500 text-white w-24 py-1.5 rounded"
          >
            Save
          </button>
          <button
            type="button"
            className="border border-gray w-24 py-1.5 rounded"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </Dialog>
  );
}
