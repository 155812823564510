import { Field, Checkbox as HeadlessCheckBox, Label } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { forwardRef } from "react";

interface Props {
  label?: string;
  value: boolean;
  onChange: (v: boolean) => void;
}

const Checkbox = forwardRef(({ label, value, onChange }: Props, _) => {
  return (
    <Field className="flex items-center gap-2 my-1">
      <HeadlessCheckBox
        checked={value ?? false}
        onChange={onChange}
        className="cursor-pointer outline-none group size-6 rounded-md p-1 flex items-center justify-center border border-gray data-[checked]:bg-white"
      >
        <CheckIcon className="hidden size-5 text-blue-500 group-data-[checked]:block" />
      </HeadlessCheckBox>
      {label && <Label>{label}</Label>}
    </Field>
  );
});

export default Checkbox;
