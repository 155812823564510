import {
  Field,
  Label,
  Radio,
  RadioGroup as HeadlessRadioGroup,
} from "@headlessui/react";
import { forwardRef } from "react";

interface Option {
  value: string;
  label: string;
}

interface Props {
  label: string;
  value: string;
  options: Option[];
  onChange: (v: string) => void;
  size?: "md" | "lg";
}

const RadioGroup = forwardRef(
  ({ label, value = '', options, onChange, size = "md" }: Props, _) => {
    return (
      <div className={`mb-2 ${size === "md" ? "text-sm" : ""}`}>
        <div className="mb-2 font-medium">{label}:</div>
        <HeadlessRadioGroup value={value ?? null} onChange={onChange}>
          {options.map((option) => (
            <Field key={option.value} className="flex items-center gap-2">
              <Radio
                value={option.value}
                className={`group flex items-center justify-center rounded-full border`}
              >
                <span className="invisible size-2 rounded-full bg-blue-500 group-data-[checked]:visible" />
              </Radio>
              <Label>{option.label}</Label>
            </Field>
          ))}
        </HeadlessRadioGroup>
      </div>
    );
  }
);

export default RadioGroup;
