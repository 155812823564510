import { Control, Controller } from "react-hook-form";
import { Field, Label, Select } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import RadioGroup from "./ui/RadioGroup";
import Checkbox from "./ui/Checkbox";
import FileDropZone from "./ui/FileDropZone";
import { Category } from "../types/Category";
import { BaseSyntheticEvent, useMemo } from "react";

interface StepOneProps {
  control: Control<any>;
  report_type: string;
  is_competition: boolean;
  nextStep: (
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  categories: Category[]; // Accept categories as props
}

const StepOne: React.FC<StepOneProps> = ({
  control,
  is_competition,
  report_type,
  nextStep,
  categories,
}) => {
  const filteredCategories = useMemo(
    () =>
      categories
        .filter((c) => (is_competition ? c.is_competition : !c.is_competition))
        .map((c) => (
          <option value={c.id} key={c.id}>
            {c.name}
          </option>
        )),
    [categories, is_competition]
  );

  return (
    <form onSubmit={nextStep}>
      <Controller
        control={control}
        rules={{ required: true }}
        name="image_url"
        render={({ field }) => (
          <div className="my-4">
            <FileDropZone label="Upload Image" {...field} />
          </div>
        )}
      />
      {/* Report Type Selection */}
      <div className="mb-4">
        <Controller
          control={control}
          name="report_type"
          rules={{ required: true }}
          render={({ field }) => (
            <RadioGroup
              {...field}
              size="lg"
              label="Report Type"
              options={[
                {
                  value: "tableau",
                  label: "Tableau Report",
                },
                {
                  value: "custom",
                  label: "Custom Report",
                },
                {
                  value: "web_link",
                  label: "Web Link",
                },
              ]}
            />
          )}
        />
      </div>

      <label className="block mb-2">Report URL:</label>
      <Controller
        name="report_url"
        control={control}
        rules={{ required: report_type !== "custom" }}
        render={({ field }) => (
          <input {...field} type="url" className="w-full p-2 border mb-4" />
        )}
      />

      <label className="block mb-2">Report Name:</label>
      <Controller
        name="report_name"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <input {...field} className="w-full p-2 border mb-4" />
        )}
      />

      <label className="block mb-2">Alias:</label>
      <Controller
        name="alias"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <input {...field} className="w-full p-2 border mb-4" />
        )}
      />

      <label className="block mb-2">Description:</label>
      <Controller
        name="description"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <textarea {...field} rows={4} className="w-full p-2 border mb-4" />
        )}
      />

      <label className="block mb-2">Tutorial Video Link:</label>
      <Controller
        name="tutorial_video_link"
        control={control}
        render={({ field }) => (
          <input {...field} className="w-full p-2 border mb-4" />
        )}
      />

      <div className="pt-3">
        <Controller
          name="is_competition"
          control={control}
          render={({ field }) => <Checkbox {...field} label="Competition" />}
        />
      </div>

      {is_competition && (
        <>
          <div className="pt-2">
            <Controller
              name="is_featured_competition"
              control={control}
              render={({ field }) => <Checkbox {...field} label="Featured" />}
            />
          </div>
        </>
      )}

      {/* Dropdown to select category */}
      <Field className="pt-4 mb-2">
        <Label className="">Category:</Label>
        <Controller
          name="category_id"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <div className="relative">
              <Select
                {...field}
                value={field.value || ""}
                className="block mt-2 w-full appearance-none rounded border py-2 px-2"
              >
                <option className="text-gray-500" disabled value="">
                  No Category Selected
                </option>
                {filteredCategories}
              </Select>

              <ChevronDownIcon className="group pointer-events-none absolute top-3 right-2.5 size-4 text-gray-500" />
            </div>
          )}
        />
      </Field>

      <button
        type="submit"
        className="rounded bg-blue-500 text-white px-4 py-2 mt-8 w-full md:w-[240px]"
      >
        Next
      </button>
    </form>
  );
};

export default StepOne;
