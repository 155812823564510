import { useEffect, useState } from "react";
import { Controlled as ControlledEditor } from "react-codemirror2";
import { Button, Input } from "@headlessui/react";
import "codemirror/theme/material.css";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/sql/sql";

import { Report, ReportField } from "../../types/Report";
import Dialog from "../ui/Dialog";
import api from "../../axios";

interface Props {
  report?: Report;
  onSave: () => void;
  onCancel: () => void;
}

function convertSnakeCaseToReadable(snakeCaseString: string) {
  return snakeCaseString
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export default function CustomReportEdit({ report, onCancel, onSave }: Props) {
  const [query, setQuery] = useState("");
  const [sqlUpdated, setSqlUpdated] = useState(false);
  const [reportFields, setReportFields] = useState<Partial<ReportField>[]>([]);

  const fetchReportInfo = async () => {
    if (!report) {
      return;
    }
    const { data } = await api.get("/reports/info", {
      params: {
        reportName: report.alias,
      },
    });
    setQuery(data.base_query?.trim());

    const { report_table_fields, report_table_field_names } = data;

    setReportFields(
      report_table_fields?.map((f: any, index: number) => ({
        field: f,
        column_name: report_table_field_names[index],
        report_id: report.id,
      })) ?? []
    );
  };

  useEffect(() => {
    if (!report) {
      return;
    }

    if (report.query) {
      setQuery(report.query);
      setReportFields(report.report_fields);
    } else {
      fetchReportInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  function extractFieldsWithTables(sql: string) {
    const fieldsWithTables: any = [];

    // Regex to find each CTE block
    const cteBlockRegex =
      /(\w+)\s+AS\s+\(\s*SELECT\s+([\s\S]*?)\s+FROM\s+([\s\S]*?)(?=\),|$)/gi;

    let cteMatch: RegExpExecArray | null;

    while ((cteMatch = cteBlockRegex.exec(sql)) !== null) {
      const tableAlias = cteMatch[1]; // e.g., BASE_QUERY, RWS, MAX_PRR
      const selectClause = cteMatch[2];
      const asRegex = /\s+AS\s+(\w+)/gi;

      let match: RegExpExecArray | null;
      while ((match = asRegex.exec(selectClause)) !== null) {
        const field = match[1];
        fieldsWithTables.push({ table: tableAlias, field });
      }
    }

    const finalSelect = sql.split("SELECT").pop();
    let finalFields: any[] = [];

    if (finalSelect) {
      finalSelect.split(",").forEach((field) => {
        const fieldMatch = field.match(/(\w+)\.(\w+|\*)\s*(?:AS\s+(\w+))?/i);
        if (fieldMatch) {
          const alias = finalSelect
            .split("FROM")
            .pop()
            ?.match(new RegExp(`(\\w+)\\s+(?=${fieldMatch[1]})`));
          const table = alias?.[1];
          const name = fieldMatch[3] || fieldMatch[2] || "";

          finalFields = finalFields.concat(
            name === "*"
              ? fieldsWithTables
                  .filter(
                    (f: any) =>
                      f.table === table &&
                      !["photo", "group_field"].includes(f.field)
                  )
                  .map((f: any) => f.field)
              : name
          );
        }
      });
    }

    return finalFields;
  }

  useEffect(() => {
    if (!sqlUpdated || !report) {
      return;
    }
    const fields = extractFieldsWithTables(query);
    const finalFields = fields.map(
      (f) =>
        (reportFields.find((rf) => rf.field === f) as ReportField) ?? {
          report_id: report.id,
          field: f,
          column_name: convertSnakeCaseToReadable(f),
        }
    );

    setReportFields(finalFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sqlUpdated]);

  const handleSaveFields = async () => {
    if (!report) {
      return;
    }
    await api.patch(`/reports/${report.id}/`, {
      ...report,
      category_id: report.category.id,
      query,
    });
    await api.post(`/reports/${report.id}/fields`, reportFields);
    onSave();
  };

  return (
    <Dialog title="Custom Report" open={!!report} onClose={onCancel}>
      {!sqlUpdated ? (
        <div>
          <ControlledEditor
            onBeforeChange={(_, __, v) => setQuery(v)}
            value={query}
            options={{
              lineWrapping: true,
              lint: true,
              mode: "sql",
              lineNumbers: true,
            }}
          />
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => setSqlUpdated(true)}
              className="px-4 py-2 rounded bg-blue-500 w-[120px] text-white"
            >
              Next
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <div className="grid grid-cols-4 gap-3">
            <div className="col-span-2">Field</div>
            <div className="col-span-2">Column Name</div>
          </div>
          {reportFields.map((f, index) => (
            <div key={f.field} className="grid grid-cols-4 gap-3">
              <div className="col-span-2">
                <Input
                  className="pointer-events-none border border-gray-300 rounded p-1 w-full"
                  readOnly
                  value={f.field}
                />
              </div>
              <div className="col-span-2">
                <Input
                  value={f.column_name}
                  className="border border-gray-300 rounded p-1 w-full"
                  onChange={(e) =>
                    setReportFields((fields) =>
                      fields.map((field) => ({
                        ...field,
                        column_name:
                          field.field === f.field
                            ? e.target.value
                            : field.column_name,
                      }))
                    )
                  }
                />
              </div>
            </div>
          ))}
          <div className="flex justify-end gap-3 mt-4">
            <Button
              onClick={() => setSqlUpdated(false)}
              className="px-4 py-2 rounded bg-blue-500 w-[120px] text-white"
            >
              Back
            </Button>
            <Button
              onClick={() => handleSaveFields()}
              className="px-4 py-2 rounded bg-blue-500 w-[120px] text-white"
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
}
