import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { Field, Input, Label, Select, Textarea } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import { Report } from "../../types/Report";
import Dialog from "../ui/Dialog";
import { Category } from "../../types/Category";
import RadioGroup from "../ui/RadioGroup";
import FileDropZone from "../ui/FileDropZone";
import Checkbox from "../ui/Checkbox";

interface Props {
  report?: Report;
  categories: Category[];
  onSubmit: (report: Partial<Report>) => void;
  onCancel: () => void;
}

export default function ReportEditDialog({
  report,
  categories,
  onSubmit,
  onCancel,
}: Props) {
  const {
    watch,
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    reset({
      report_name: report?.report_name ?? "",
      report_url: report?.report_url ?? "",
      alias: report?.alias ?? "",
      description: report?.description ?? "",
      category_id: report?.category.id ?? "",
      report_type: report?.report_type ?? "",
      image_url: report?.image_url ?? "",
      tutorial_video_link: report?.tutorial_video_link ?? "",
      is_featured_competition: report?.is_featured_competition ?? undefined,
    });
  }, [report, reset]);

  return (
    <Dialog title="Edit Report" open={!!report} onClose={onCancel}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          rules={{ required: true }}
          name="image_url"
          render={({ field }) => (
            <div className="mb-4">
              <label className="font-medium">Image: </label>
              <FileDropZone label="Upload Image" {...field} />
            </div>
          )}
        />

        <Field>
          <Label className="font-medium text-sm">Report Name:</Label>
          <Input
            {...register("report_name", { required: true })}
            placeholder="Enter report name"
            className="w-full mt-2 p-2 border rounded"
          />
          {errors.report_name && (
            <div className="text-sm ml-2 text-red-500">
              The field is required.
            </div>
          )}
        </Field>

        <div className="mt-3">
          <Controller
            control={control}
            name="report_type"
            render={({ field }) => (
              <RadioGroup
                {...field}
                label="Report Type"
                options={[
                  {
                    value: "tableau",
                    label: "Tableau Report",
                  },
                  {
                    value: "custom",
                    label: "Custom Report",
                  },
                  {
                    value: "web_link",
                    label: "Web Link",
                  },
                ]}
              />
            )}
          />
        </div>

        <Field className="mt-3">
          <Label className="font-medium text-sm">Report URL:</Label>
          <Input
            {...register("report_url", {
              required: watch("report_type") !== "custom",
            })}
            placeholder="Enter report url"
            className="w-full mt-2 p-2 border rounded"
          />
          {errors.report_url && (
            <div className="text-sm ml-2 text-red-500">
              The field is required.
            </div>
          )}
        </Field>

        <Field className="mt-3">
          <Label className="font-medium text-sm">Alias:</Label>
          <Input
            {...register("alias", { required: true })}
            placeholder="Enter alias"
            className="w-full mt-2 p-2 border rounded"
          />
          {errors.alias && (
            <div className="text-sm ml-2 text-red-500">
              The field is required.
            </div>
          )}
        </Field>

        <Field className="mt-3">
          <Label className="font-medium text-sm">Description:</Label>
          <Textarea
            {...register("description")}
            placeholder="Enter description"
            className="w-full mt-2 p-2 border rounded"
          />
        </Field>

        <Field className="mt-3">
          <Label className="font-medium text-sm">Tutorial Video Link:</Label>
          <Input
            {...register("tutorial_video_link")}
            placeholder="Enter tutorial video link"
            className="w-full mt-2 p-2 border rounded"
          />
        </Field>

        <Field className="mt-3">
          <Label className="font-medium text-sm">Category:</Label>
          <div className="relative">
            <Select
              {...register("category_id")}
              className="block mt-2 w-full appearance-none rounded border py-2 px-2"
            >
              {categories
                .filter((c) =>
                  report?.is_competition ? c.is_competition : !c.is_competition
                )
                .map((c) => (
                  <option value={c.id} key={c.id}>
                    {c.name}
                  </option>
                ))}
            </Select>

            <ChevronDownIcon className="group pointer-events-none absolute top-2.5 right-2.5 size-4 text-gray-500" />
          </div>
        </Field>

        {report?.is_competition && (
          <>
            <div className="mt-4">
              <Controller
                control={control}
                name="is_featured_competition"
                render={({ field }) => <Checkbox {...field} label="Featured" />}
              />
            </div>
          </>
        )}

        <div className="flex justify-end gap-2 mt-6">
          <button
            type="submit"
            className="bg-blue-500 text-white w-24 py-1.5 rounded"
          >
            Save
          </button>
          <button
            type="button"
            className="border border-gray w-24 py-1.5 rounded"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </Dialog>
  );
}
