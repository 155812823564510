import { ArrowUpTrayIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { forwardRef, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { API_BASE_URL } from "../../const/config";
import { Button } from "@headlessui/react";
import Spinner from "./Spinner";

interface Props {
  value: string;
  onChange: (value: string) => void;
  label?: string;
}

const FileDropZone = forwardRef(({ label, onChange, value = "" }: Props, _) => {
  const [uploading, setUploading] = useState(false);

  const uploadImage = async (file: File) => {
    setUploading(true);

    const formData = new FormData();
    formData.append("file", file);

    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${API_BASE_URL}/image_upload/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        onChange(result.image_url);
      } else {
        console.error("File upload failed:", response);
      }
    } catch (err) {
      console.error(err);
    }
    setUploading(false);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const [file] = acceptedFiles;
    uploadImage(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
      "image/jpg": [],
    },
    useFsAccessApi: false,
    noClick: true,
    multiple: false,
  });

  return (
    <div
      {...getRootProps()}
      className="relative !border-bodydark1 my-2 w-full bg-slate-100 hover:!border-primary dark:!border-strokedark dark:bg-graydark dark:hover:!border-primary cursor-pointer rounded-md border border-dashed py-4"
      onClick={open}
    >
      {uploading ? (
        <div className="flex justify-center">
          <Spinner />
        </div>
      ) : value ? (
        <div className="flex justify-center">
          <img className="size-36" src={value} alt="" />
          <Button
            className="absolute top-2 right-2"
            onClick={() => onChange("")}
          >
            <XMarkIcon className="size-5" />
          </Button>
        </div>
      ) : (
        <>
          <input {...getInputProps()} />
          <div className="flex flex-col items-center py-8">
            <div className="mb-2.5 flex justify-center">
              <div className="shadow-10 h-15 w-15 flex items-center justify-center">
                <ArrowUpTrayIcon className="size-8" />
              </div>
            </div>
            <span className="text-graydark">
              {label ?? "Drop files here to upload"}
            </span>
          </div>
        </>
      )}
    </div>
  );
});

export default FileDropZone;
