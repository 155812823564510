import { useMemo } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import { Category } from "../../types/Category";
import ActionMenu from "./ActionMenu";
import { Report } from "../../types/Report";
import Badge from "../ui/Badge";

export interface SortableItem {
  category: Category;
  reports: Report[];
}

interface Props {
  isCompetition: boolean;
  categories: Category[];
  reports: Report[];
  onCategoriesUpdate: (value: Category[]) => void;
  onReportsUpdate: (value: Report[]) => void;
  onEditCategory: (category: Category) => void;
  onEditReport: (report: Report) => void;
  onDeleteCategory: (item: SortableItem) => void;
  onDeleteReport: (report: Report) => void;
  onEditSql: (report: Report) => void;
}

export default function SortableList({
  isCompetition,
  categories,
  reports,
  onCategoriesUpdate,
  onReportsUpdate,
  onDeleteCategory,
  onDeleteReport,
  onEditCategory,
  onEditReport,
  onEditSql,
}: Props) {
  const items = useMemo<SortableItem[]>(
    () =>
      categories
        .filter((c) => (isCompetition ? c.is_competition : !c.is_competition))
        .map((category) => ({
          category,
          reports: reports
            .filter((r) => r.category.id === category.id)
            .sort((r1, r2) => r1.position - r2.position),
        }))
        .sort((c1, c2) => c1.category.position - c2.category.position),
    [categories, reports, isCompetition]
  );

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const source = result.source;
    const destination = result.destination;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (result.type === "CATEGORY") {
      const reordered = reorder(items, source.index, destination.index);

      onCategoriesUpdate(
        items
          .map((item) => item.category)
          .map((category) => ({
            ...category,
            position: reordered.findIndex(
              (item) => category.id === item.category.id
            ),
          }))
      );
      return;
    }

    const current = items.find(
      (item) => item.category.id === +source.droppableId
    );
    const next = items.find(
      (item) => item.category.id === +destination.droppableId
    );

    if (!current || !next) {
      return;
    }
    const target = current?.reports[source.index];

    if (source.droppableId === destination.droppableId) {
      const reordered = reorder(
        current.reports,
        source.index,
        destination.index
      );
      onReportsUpdate(
        items
          .reduce(
            (reports, item) => reports.concat(item.reports),
            [] as Report[]
          )
          .map((report) => {
            const index = reordered.findIndex((item) => item.id === report.id);
            return {
              ...report,
              position: index > -1 ? index : report.position,
            };
          })
      );
      return;
    }

    current.reports.splice(source.index, 1);
    next.reports.splice(destination.index, 0, target);
    target.category = next.category;

    onReportsUpdate(
      items
        .reduce((reports, item) => reports.concat(item.reports), [] as Report[])
        .map((report) => {
          const indexOne = current.reports.findIndex(
            (item) => item.id === report.id
          );
          const indexTwo = next.reports.findIndex(
            (item) => item.id === report.id
          );
          const index = indexOne > -1 ? indexOne : indexTwo;

          return {
            ...report,
            category:
              report.id === target.id ? target.category : report.category,
            position: index > -1 ? index : report.position,
          };
        })
    );
  };

  return (
    <div>
      <div className="mb-3 font-medium">
        {isCompetition ? "Competitions" : "Reports"}
      </div>
      {!items.length && <div>No Categories</div>}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="categories" type="CATEGORY">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable
                  key={item.category.id}
                  draggableId={item.category.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      key={item.category.id}
                      className="p-2 select-none cursor-grab rounded mb-2 bg-slate-100 border border-slate-200"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                      }}
                    >
                      <div className="flex justify-between items-start px-2">
                        <div className="font-semibold flex items-center gap-2">
                          {/* {item.category.image_url && (
                            <img
                              src={item.category.image_url}
                              alt=""
                              className="size-24"
                            />
                          )} */}
                          <span>{item.category.name}</span>
                          {item.category.is_featured_competition && (
                            <Badge text="Featured" />
                          )}
                        </div>
                        <ActionMenu
                          onEdit={() => onEditCategory(item.category)}
                          onDelete={() => onDeleteCategory(item)}
                        />
                      </div>
                      <Droppable
                        droppableId={item.category.id.toString()}
                        type="REPORT"
                      >
                        {(provided) => (
                          <div
                            className="mt-2"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {item.reports.length ? (
                              item.reports.map((report, index) => (
                                <Draggable
                                  key={report.id}
                                  draggableId={"report-" + report.id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      key={report.id}
                                      className="py-2 px-4 select-none cursor-grab rounded mb-1 text-sm bg-white border border-slate-200"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <div className="mb-2 mt-1 flex items-center justify-between">
                                        <div className="font-semibold text-base flex items-center gap-2">
                                          <span>{report.report_name}</span>
                                          <div>
                                            {report.is_featured_competition && (
                                              <Badge text="Featured" />
                                            )}
                                          </div>
                                        </div>
                                        <ActionMenu
                                          report={report}
                                          onEdit={() => onEditReport(report)}
                                          onDelete={() =>
                                            onDeleteReport(report)
                                          }
                                          onEditSQL={() => onEditSql(report)}
                                        />
                                      </div>
                                      <div className="w-full overflow-hidden overflow-ellipsis">
                                        <p>
                                          <span className="font-semibold">
                                            Link:{" "}
                                          </span>
                                          <a href={report.report_url}>
                                            {report.report_url}
                                          </a>
                                        </p>
                                      </div>

                                      <div>
                                        <p>
                                          <span className="font-semibold">
                                            Alias:{" "}
                                          </span>{" "}
                                          {report.alias}
                                        </p>
                                        <p>
                                          <span className="font-semibold">
                                            Report Type:{" "}
                                          </span>{" "}
                                          {report.report_type}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))
                            ) : (
                              <div className="text-sm">
                                No reports for this category
                              </div>
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
