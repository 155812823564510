import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  CircleStackIcon,
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Report } from "../../types/Report";

interface Props {
  report?: Report;
  onEdit: () => void;
  onDelete: () => void;
  onEditSQL?: () => void;
}

// Dropdown menu for Category / Report actions
export default function ActionMenu({
  onEdit,
  onDelete,
  onEditSQL = () => null,
  report,
}: Props) {
  return (
    <Menu>
      <MenuButton>
        <EllipsisHorizontalIcon className="size-5" />
      </MenuButton>

      <MenuItems
        transition
        anchor="bottom end"
        className="w-52 origin-top-right rounded-md border shadow border-slate-200 bg-white p-1 text-sm/6 transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        {report && report.report_type === "custom" && (
          <MenuItem>
            <button
              onClick={onEditSQL}
              className="group flex w-full items-center gap-3 rounded py-1 px-3 data-[focus]:bg-slate-100"
            >
              <CircleStackIcon className="size-4 fill-white/30" />
              Edit SQL
            </button>
          </MenuItem>
        )}
        <MenuItem>
          <button
            onClick={onEdit}
            className="group flex w-full items-center gap-3 rounded py-1 px-3 data-[focus]:bg-slate-100"
          >
            <PencilIcon className="size-4 fill-white/30" />
            Edit
          </button>
        </MenuItem>
        <div className="my-1 h-px bg-slate-200" />
        <MenuItem>
          <button
            onClick={onDelete}
            className="group flex w-full items-center gap-3 rounded py-1 px-3 data-[focus]:bg-slate-100 text-red-500"
          >
            <TrashIcon className="size-4 fill-white/30" />
            Delete
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
}
