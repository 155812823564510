import { useState } from "react";

import { Category } from "../../types/Category";
import { Report } from "../../types/Report";
import CategoryEditDialog from "./CategoryEditDialog";
import ReportEditDialog from "./ReportEditDialog";
import SortableList from "./SortableList";
import CustomReportEdit from "./CustomReportEdit";

interface Props {
  categories: Category[];
  reports: Report[];
  onCategoriesUpdate: (value: Category[]) => void;
  onReportsUpdate: (value: Report[]) => void;
  onDeleteCategory: (category: Category) => void;
  onDeleteReport: (report: Report) => void;
  onCategoryUpdate: (id: number, category: Partial<Category>) => void;
  onReportUpdate: (id: number, category: Partial<Report>) => void;
  onReportQueryUpdate: () => void;
}

interface SortableItem {
  category: Category;
  reports: Report[];
}

export default function Sidebar({
  categories,
  reports,
  onCategoryUpdate,
  onCategoriesUpdate,
  onReportsUpdate,
  onDeleteCategory,
  onDeleteReport,
  onReportUpdate,
  onReportQueryUpdate
}: Props) {
  const [editingCategory, setEditingCategory] = useState<Category>();
  const [editingReport, setEditingReport] = useState<Report>();
  const [editingSQLReport, setEditingSQLReport] = useState<Report>();

  const handleDeleteCategory = (item: SortableItem) => {
    if (item.reports.length) {
      return alert("The category is not empty");
    }
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Do you really want to remove the category?")) {
      return;
    }
    onDeleteCategory(item.category);
  };

  const handleDeleteReport = (report: Report) => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Do you really want to remove the report?")) {
      return;
    }
    onDeleteReport(report);
  };

  return (
    <div
      // style={{ height: "calc(100% - 24px)" }}
      className="lg:h-[calc(100%-24px)] container overflow-y-auto mx-auto my-4 p-4 shadow rounded lg:w-[480px] w-full border border-slate-100"
    >
      {[true, false].map((f, index) => (
        <SortableList
          key={index}
          isCompetition={f}
          categories={categories}
          reports={reports}
          onCategoriesUpdate={onCategoriesUpdate}
          onReportsUpdate={onReportsUpdate}
          onDeleteCategory={handleDeleteCategory}
          onDeleteReport={handleDeleteReport}
          onEditCategory={setEditingCategory}
          onEditReport={setEditingReport}
          onEditSql={setEditingSQLReport}
        />
      ))}
      <CategoryEditDialog
        category={editingCategory}
        onSubmit={(data) => {
          if (editingCategory) {
            onCategoryUpdate(editingCategory.id, data);
          }
          setEditingCategory(undefined);
        }}
        onCancel={() => setEditingCategory(undefined)}
      />

      <ReportEditDialog
        report={editingReport}
        categories={categories}
        onSubmit={(data) => {
          if (editingReport) {
            onReportUpdate(editingReport.id, data);
          }
          setEditingReport(undefined);
        }}
        onCancel={() => setEditingReport(undefined)}
      />

      {editingSQLReport && (
        <CustomReportEdit
          report={editingSQLReport}
          onSave={() => {
            setEditingSQLReport(undefined);
            onReportQueryUpdate();
          }}
          onCancel={() => setEditingSQLReport(undefined)}
        />
      )}
    </div>
  );
}
