import { UseFormReset } from "react-hook-form";

import { API_BASE_URL } from "../const/config";
import { Report } from "../types/Report";
import { Category } from "../types/Category";

interface StepProps {
  prevStep: () => void;
  categories: Category[];
  formData: Partial<Report>;
  reset: UseFormReset<Partial<Report>>;
}

const StepTwo = ({ prevStep, categories, formData, reset }: StepProps) => {
  const category = categories.find(
    (c) => +(formData.category_id ?? "") === c.id
  );

  const handleSubmit = async () => {
    console.log("Submitted Data:", formData);

    // Retrieve JWT token from localStorage
    const token = localStorage.getItem("token");
    if (!token) {
      alert("You need to be logged in to submit a report.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/reports/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const message = await response.json();
        throw new Error(message.detail || "Error submitting report");
      }

      // Navigate to a success or dashboard page after successful submission
      alert("Report submitted successfully!");
      reset({
        report_url: "",
        report_name: "",
        report_type: undefined,
        is_competition: false,
        image_url: "",
        description: "",
        category_id: undefined,
        is_featured_competition: false,
        alias: "",
      });
      prevStep();
    } catch (err: any) {
      console.error("Error submitting report:", err);
      alert(err.message || "An error occurred during report submission.");
    }
  };

  return (
    <div className="flex flex-col gap-1">
      <h2 className="text-xl font-bold mb-4">Review and Submit</h2>
      <p>
        <strong>Report Type:</strong>{" "}
        {formData.report_type === "tableau"
          ? "Tableau Report"
          : formData.report_type === "custom"
          ? "Custom Report"
          : "Web Link"}
      </p>

      <div className="w-full">
        <strong>Report URL:</strong>
        <div className="w-full break-words">{formData.report_url ?? ""}</div>
      </div>

      <p>
        <strong>Report Name:</strong> {formData.report_name ?? ""}
      </p>
      <p>
        <strong>Alias:</strong> {formData.alias}
      </p>
      <p>
        <strong>Description:</strong> {formData.description ?? ""}
      </p>
      <p>
        <strong>Is Competition:</strong>{" "}
        {formData.is_competition ? "Yes" : "No"}
      </p>
      {formData.is_competition && (
        <>
          <div>
            <strong>Is Featured:</strong>{" "}
            {formData.is_featured_competition ? "Yes" : "No"}
          </div>
          <div>
            <strong>Image:</strong>
            <div className="mt-2">
              <img src={formData.image_url} alt="" className="size-36" />
            </div>
          </div>
        </>
      )}
      <p>
        <strong>Category:</strong> {category?.name ?? ""}
      </p>
      <div className="flex gap-2 mt-8">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded w-full md:w-[180px]"
        >
          Back
        </button>
        <button
          onClick={handleSubmit}
          className="bg-green-500 text-white px-4 py-2 rounded w-full md:w-[180px]"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default StepTwo;
