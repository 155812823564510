interface Props {
  size?: number;
}

export default function Spinner({ size }: Props) {
  return (
    <div
      className={`size-8 animate-spin rounded-full border-2 border-solid border-blue-500 border-t-transparent`}
    />
  );
}
