import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

import { Category } from "../../types/Category";
import FileDropZone from "../ui/FileDropZone";
import Checkbox from "../ui/Checkbox";

interface Props {
  onSubmit: (data: Partial<Category>) => void;
}

export default function CreateCategory({ onSubmit }: Props) {
  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm();

  useEffect(() => {
    reset({
      name: "",
      is_competition: false,
      is_featured_competition: false,
      image_url: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  return (
    <div>
      <h2 className="text-xl font-semibold mb-2">Add New Category:</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mb-6">
        <input
          {...register("name", {
            required: true,
          })}
          type="text"
          className="border p-2 mb-4"
          placeholder="Enter category name"
          required
        />

        <Controller
          name="is_competition"
          control={control}
          render={({ field }) => <Checkbox {...field} label="Competition" />}
        />

        {watch("is_competition") && (
          <>
            <Controller
              control={control}
              name="is_featured_competition"
              render={({ field }) => <Checkbox {...field} label="Featured" />}
            />
            <Controller
              control={control}
              name="image_url"
              render={({ field }) => (
                <FileDropZone label="Upload Image" {...field} />
              )}
            />
          </>
        )}

        <button type="submit" className="bg-blue-500 text-white px-4 py-2 mt-6">
          Add Category
        </button>
      </form>
    </div>
  );
}
