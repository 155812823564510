import {
  Dialog as HeadlessDialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  open: boolean;
  onClose: () => void;
  title: string;
}

export default function Dialog({ open, onClose, title, children }: Props) {
  return (
    <HeadlessDialog
      open={open}
      className="relatvie z-1 focus:outline-none"
      onClose={onClose}
    >
      <div className="fixed inset-0 z-1 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 bg-black/80">
          <DialogPanel
            transition
            className="w-full max-w-xl rounded bg-white p-3 duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
          >
            <DialogTitle
              as="h3"
              className="text-lg font-medium flex justify-between items-center border-b border-slate-200 pt-2 pb-4"
            >
              {title}
              <button
                className="hover:opacity-80 transition-opacity"
                onClick={onClose}
              >
                <XMarkIcon className="size-4" />
              </button>
            </DialogTitle>
            <div className="pt-4 text-sm">{children}</div>
          </DialogPanel>
        </div>
      </div>
    </HeadlessDialog>
  );
}
